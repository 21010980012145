import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/apulanta/apulanta1.jpg";
import image2 from "assets/img/cases/apulanta/apulanta2.jpg";
import image3 from "assets/img/cases/apulanta/apulanta3.jpg";

class VRVideoExperiencesSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>VR Video Experiences</h2>
              <h4>Customized Solutions for VR Live Streams</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Case Apulanta",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Our collaboration with the famous Finnish rock band Apulanta started already in 2013,
                          when we created the very first 360° music video in Finland for them. In our latest common 
                          project from 2023, we created a 360° live stream experience for the band's intimate concert
                          from their own training facility and museum Apulandia, targeted towards their most dedicated fans.
                        </p>
                        <p>
                          360° video is a great way to bring the viewer closer to the action. It works especially 
                          well for capturing live events from smaller rooms such as a band's training facility
                          or a small club. The viewer can be in the middle of the action, seeing the band members 
                          from close up, and looking around the room exploring it at own pace, without a TV show style 
                          fast cuts. The experience is very immersive, personal and intimate.
                        </p>
                        <p>
                          It was only natural to choose VR video as the platform for this experience, as Apulanta wanted to
                          offer something special for free for their most dedicated fans. For a single event, it
                          was not feasible to create a custom app, so we used our web player solution, which does
                          not require any installation and works on all devices.
                        </p>
                        <p>
                          There was a need to limit the streaming costs, as this event was free for the viewers.
                          Since Apulanta is very popular, there was a significant risk of a large number of viewers
                          and the associated costs. We created a solution that was very simple for the viewers to use,
                          but allowed limiting the experience to selected group of viewers.
                        </p>
                        <p>
                          <b>
                            We create customized VR video experiences for mobile, desktop, and VR headsets, and 
                            live streaming services with our close partner FinCloud Ltd. Contact us
                            to discuss about your project or idea, and request a quote.
                          </b>
                        </p>
                        <Button
                            round
                            color="success"
                            size="md"
                            href="contact-page/"
                            target="_self"
                            rel="noopener noreferrer"
                          >
                            Contact us
                        </Button>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(VRVideoExperiencesSection);
